import { FormatNumber, FormatPercent, Stack } from '@segunosoftware/equinox';
import { SkeletonBodyText } from '@shopify/polaris';
import { useMarketingPlatformMailchimpDetails } from '../../hooks/marketingplatform/useMarketingPlatformAccount';

export default function MailchimpDetails({ accountId }: { accountId: string | number }) {
	const { mailchimpDetails, isMailchimpDetailsLoading } = useMarketingPlatformMailchimpDetails(accountId);

	return (
		<>
			{(!mailchimpDetails || isMailchimpDetailsLoading) && <SkeletonBodyText lines={3} />}
			{mailchimpDetails && (
				<Stack spacing="none" vertical>
					<div>
						<strong>List name</strong>: {mailchimpDetails.name}
					</div>
					<div>
						<strong>List created</strong>:{' '}
						{new Date(mailchimpDetails.dateCreated).toLocaleDateString('en-US', {
							year: 'numeric',
							month: '2-digit',
							day: '2-digit'
						})}
					</div>
					<div>
						<strong>Double opt-in</strong>: {mailchimpDetails.doubleOptin ? 'Yes' : 'No'}
					</div>
					<div>
						<strong>List rating</strong>: {mailchimpDetails.listRating}
					</div>
					<div>
						<strong>Open rate</strong>: <FormatPercent value={mailchimpDetails.stats.openRate} decimals={1} />
					</div>
					<div>
						<strong>Click rate</strong>: <FormatPercent value={mailchimpDetails.stats.clickRate} decimals={1} />
					</div>
					<div>
						<strong>Member count</strong>: <FormatNumber value={mailchimpDetails.stats.memberCount} />
					</div>
					<div>
						<strong>Unsubscribe count</strong>: <FormatNumber value={mailchimpDetails.stats.unsubscribeCount} />
					</div>
					<div>
						<strong>Cleaned count</strong>: <FormatNumber value={mailchimpDetails.stats.cleanedCount} />
					</div>
					<div>
						<strong>Campaign last sent</strong>:{' '}
						{mailchimpDetails.stats.campaignLastSent
							? new Date(mailchimpDetails.stats.campaignLastSent).toLocaleDateString('en-US', {
									year: 'numeric',
									month: '2-digit',
									day: '2-digit'
								})
							: 'Never'}
					</div>
					<div>
						<strong>Campaign count</strong>: <FormatNumber value={mailchimpDetails.stats.campaignCount} />
					</div>
					<div>
						<strong>Member count since send</strong>: <FormatNumber value={mailchimpDetails.stats.memberCountSinceSend} />
					</div>
					<div>
						<strong>Unsubscribe count since send</strong>: <FormatNumber value={mailchimpDetails.stats.unsubscribeCountSinceSend} />
					</div>
					<div>
						<strong>Cleaned count since send</strong>: <FormatNumber value={mailchimpDetails.stats.cleanedCountSinceSend} />
					</div>
				</Stack>
			)}
		</>
	);
}
