import { useMutation } from '@tanstack/react-query';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export function useEraseCustomerData() {
	const { post } = useAuthenticatedFetch();
	const {
		mutate: eraseCustomerData,
		data,
		isLoading: isCustomerDataErasing
	} = useMutation(email =>
		post(
			`/marketing-platform-accounts/customer-data-erasure?email=${encodeURIComponent(email)}`,
			null,
			{
				download: true,
				filename: `customer-data-erasure-${email}.csv`
			},
			{
				Accept: 'text/csv'
			}
		)
	);

	return { eraseCustomerData, isCustomerDataErasing, isCustomerDataErasureSuccessful: data?.ok ?? false };
}
