import { DateTimePicker } from '@segunosoftware/equinox';
import { Badge, Banner, ChoiceList, Form, FormLayout, InlineStack, Layout, Modal } from '@shopify/polaris';
import { addDays, fromUnixTime, getUnixTime } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePopupAccount } from '../../hooks/popups/usePopupAccount';
import { usePopupAccountActions } from '../../hooks/popups/usePopupAccountActions';
import { useUser } from '../../hooks/useUser';
import AccountView, { isAdmin, isLeadOrAdmin } from '../AccountView';
import AdminPage from '../AdminPage';
import AppCreditModal from '../AppCreditModal';
import JobsView from '../JobsView';
import PopupBillingAndUsageSummary from './PopupBillingAndUsageSummary';
import { PopupConnections } from './PopupConnections';
import PopupsView from './PopupsView';

export default function PopupAccountView() {
	const { accountId } = useParams();
	const user = useUser();
	const { account, jobs, onLoadJobs, isJobsLoading, popups, isPopupsLoading, installVerified, verifyInstallError } =
		usePopupAccount(accountId);
	const { updateTrial, isUpdatingTrial, setSuspended, isSettingSuspended } = usePopupAccountActions(accountId);

	const [showAppCreditModal, setShowAppCreditModal] = useState(false);
	const onCloseAppCreditModal = useCallback(() => setShowAppCreditModal(false), []);

	const [showUpdateTrialModal, setShowUpdateTrialModal] = useState(false);

	const [updateTrialOption, setUpdateTrialOption] = useState(['extend-trial']);
	const isExtendTrialOption = updateTrialOption[0] === 'extend-trial';
	const [trialExtensionDate, setTrialExtensionDate] = useState(null);
	const [showConfirmSuspendedModal, setShowConfirmSuspendedModal] = useState(false);

	useEffect(() => {
		if (!isUpdatingTrial) {
			onHideUpdateTrialModal();
		}
		if (!isSettingSuspended) {
			onHideConfirmSuspendedModal();
		}
	}, [isUpdatingTrial, isSettingSuspended]);

	function onShowUpdateTrialModal() {
		const trialDate = account.trialEndsAt ? getUnixTime(account.trialEndsAt) : getUnixTime(addDays(new Date(), account.trialDays));
		setTrialExtensionDate(trialDate);
		setShowUpdateTrialModal(true);
	}

	function onHideUpdateTrialModal() {
		setShowUpdateTrialModal(false);
	}

	function onUpdateTrial() {
		updateTrial(isExtendTrialOption ? trialExtensionDate : 0);
	}

	function onShowConfirmSuspendedModal() {
		setShowConfirmSuspendedModal(true);
	}

	function onHideConfirmSuspendedModal() {
		setShowConfirmSuspendedModal(false);
	}

	function getUpdateTrialOptions() {
		let options = [{ label: 'Extend trial', value: 'extend-trial' }];
		if (account.trialEndsAt) {
			options.push({ label: 'Remove trial', value: 'remove-trial' });
		}
		return options;
	}

	function getUpdateTrialBannerText() {
		const text = [
			<p key="extend">
				Extending or removing the trial will cancel any active charge.
				<br />
			</p>
		];
		if (!isExtendTrialOption) {
			text.push(<p key="remove">Removing the trial will cause a new trial to automatically begin.</p>);
		}
		return text;
	}

	const billingActions = [];

	function addBillingOptions(hasPermissions, options) {
		if (hasPermissions) {
			billingActions.push(...options);
		}
	}

	if (account) {
		addBillingOptions(isLeadOrAdmin(user), [
			{
				content: 'Update trial',
				onAction: onShowUpdateTrialModal,
				disabled: isUpdatingTrial
			},
			{
				content: 'Issue app credit',
				onAction: () => setShowAppCreditModal(true)
			}
		]);

		addBillingOptions(isAdmin(user), [
			{
				content: account.suspended ? 'Unsuspend' : 'Suspend',
				onAction: onShowConfirmSuspendedModal,
				disabled: isSettingSuspended
			}
		]);
	}
	const badges = [];
	if (account) {
		if (account.trialEndsAt) {
			badges.push(
				<Badge key="trial" tone="info">
					trial ends:{' '}
					{new Date(account.trialEndsAt).toLocaleDateString('en-US', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit'
					})}
				</Badge>
			);
		}
		if (account.suspended) {
			badges.push(
				<Badge key="suspended" tone="warning">
					suspended
				</Badge>
			);
		}
	}
	return (
		<AdminPage
			title={
				<InlineStack align="center">
					<div>{account?.name ?? 'Popup account'}</div>
					{badges}
				</InlineStack>
			}
			pageTitle={account?.name ?? 'Popup account'}
			backAction={{ content: 'Dashboard', url: '/' }}
			actionGroups={[{ title: 'More actions', actions: billingActions }]}>
			<Layout sectioned>
				<AccountView account={account} app="POPUP" />
				{account && (
					<>
						<PopupBillingAndUsageSummary account={account} />
						<PopupConnections account={account} />
						<PopupsView
							account={account}
							popups={popups}
							isLoading={isPopupsLoading}
							installVerified={installVerified}
							verifyInstallError={verifyInstallError}
						/>
					</>
				)}
				<JobsView onLoad={onLoadJobs} jobs={jobs} isLoading={isJobsLoading} />
				{account && (
					<Modal
						title="Update trial"
						primaryAction={{
							content: isExtendTrialOption ? 'Extend' : 'Remove',
							onAction: onUpdateTrial,
							loading: isUpdatingTrial,
							disabled: isExtendTrialOption && !trialExtensionDate,
							destructive: !isExtendTrialOption
						}}
						secondaryActions={[{ content: 'Cancel', onAction: onHideUpdateTrialModal }]}
						onClose={onHideUpdateTrialModal}
						open={showUpdateTrialModal}>
						<Modal.Section>
							<Form onSubmit={onUpdateTrial}>
								<FormLayout>
									{getUpdateTrialBannerText().length > 0 && <Banner tone="critical">{getUpdateTrialBannerText()}</Banner>}
									<ChoiceList choices={getUpdateTrialOptions()} selected={updateTrialOption} onChange={setUpdateTrialOption} />
									{isExtendTrialOption && (
										<DateTimePicker
											dateLabel="End date"
											timeLabel="End time"
											timezone={account.timezone}
											disableDatesBefore={new Date()}
											onChange={date => setTrialExtensionDate(getUnixTime(date))}
											value={fromUnixTime(trialExtensionDate)}
										/>
									)}
								</FormLayout>
							</Form>
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Confirm suspension"
						primaryAction={{
							content: account.suspended ? 'Unsuspend' : 'Suspend',
							onAction: () => setSuspended(!account.suspended),
							destructive: true,
							loading: isSettingSuspended
						}}
						secondaryActions={[{ content: 'Cancel', onAction: onHideConfirmSuspendedModal }]}
						onClose={onHideConfirmSuspendedModal}
						open={showConfirmSuspendedModal}>
						<Modal.Section>
							{account.suspended
								? 'This will unsuspend the account. All paused campaigns will need to be rescheduled and all previously activated programs will need to be reactivated.'
								: 'This will suspend the account. All scheduled campaigns will be paused and all active programs will be deactivated.'}
						</Modal.Section>
					</Modal>
				)}
			</Layout>
			{account && <AppCreditModal account={account} app="POPUP" onClose={onCloseAppCreditModal} open={showAppCreditModal} />}
		</AdminPage>
	);
}
