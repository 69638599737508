import { Card, ClipboardButton, Heading, Stack, TextStyle } from '@segunosoftware/equinox';
import { Badge, Button, Icon, InlineStack, Link, SkeletonBodyText, Tooltip } from '@shopify/polaris';
import { NoteAddIcon, ClipboardIcon, StarFilledIcon, StarIcon } from '@shopify/polaris-icons';
import styled from 'styled-components';
import { useToast } from '../ToastProvider';
import type { CanvaAccount } from '../hooks/canva/useCanvaAccount';
import type { BaseAccount, BaseBillableAccount, MarketingPlatformAccount } from '../hooks/marketingplatform/useMarketingPlatformAccount';
import type { PopupAccount } from '../hooks/popups/usePopupAccount';
import type { ReviewAccount } from '../hooks/reviews/useReviewAccount';
import { getAppDetails, useApps, type App } from '../hooks/useApps';
import { useDelegateAccessToken } from '../hooks/useDelegateAccessToken';
import { useIntercomCompany } from '../hooks/useIntercomCompany';
import { useUser, type AdminRoleFn } from '../hooks/useUser';
import AppSwitcher from './AppSwitcher';
import { renderDate } from '../utils/dateUtils';

const GOOGLE_MAPS_URL = 'https://www.google.com/maps/search/?api=1';
const getGoogleMapsUrl = (query: string) => `${GOOGLE_MAPS_URL}&query=${encodeURIComponent(query)}`;

const LEADS = ['SUPPORT_LEAD', 'DELIVERABILITY'];
export const isAdmin = (user => user?.role === 'ADMIN') as AdminRoleFn;
export const isLeadOrAdmin = (user => isAdmin(user) || LEADS.includes(user?.role ?? '')) as AdminRoleFn;
export const isSupport = (user => user?.role === 'SUPPORT' || isLeadOrAdmin(user)) as AdminRoleFn;

interface AccountViewProps {
	account: MarketingPlatformAccount | PopupAccount | ReviewAccount | CanvaAccount | BaseAccount | BaseBillableAccount;
	app: App;
}

export default function AccountView({ account, app }: AccountViewProps) {
	const user = useUser();
	const showToast = useToast();
	const { apps } = useApps(account?.shop);
	const installedApp = apps.find(a => a.app === app);
	const { requestDelegateAccessToken, isLoading, accessToken } = useDelegateAccessToken(
		installedApp ? getAppDetails(installedApp).urlPrefix : undefined,
		account?.id
	);
	const { intercomCompanyId } = useIntercomCompany(account?.shop);

	function renderTimeZone() {
		if (!account.timezone) {
			return null;
		}
		var options = {
			timeZone: account.timezone,
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric'
		} as const;
		const formatter = new Intl.DateTimeFormat([], options);
		return (
			<div>
				<strong>Timezone</strong>: {account.timezone}{' '}
				<TextStyle variation="subdued">current time: {formatter.format(new Date())}</TextStyle>
			</div>
		);
	}

	if (!account) {
		return (
			<Card title="Details" sectioned>
				<SkeletonBodyText lines={3} />
			</Card>
		);
	}
	const marketingPlatformAccount = account as MarketingPlatformAccount;
	const domain = !account.domain || account.domain.indexOf('http') === 0 ? account.domain : 'https://' + account.domain;
	const partnerLink = account && `https://partners.shopify.com/577783/stores/${account.externalId}`;
	const hubspotDealUrl = `https://app.hubspot.com/contacts/4856076/deal/${marketingPlatformAccount?.hubspotId}`;
	const helpScoutQueryUrl = `https://secure.helpscout.net/search/?query=${account.ownerEmail}`;

	const usesReviewRating = typeof account.reviewRating !== 'undefined';
	const accountSurvey = account.accountSurvey;

	return (
		<>
			<Card
				title={
					<Stack alignment="center" distribution="equalSpacing">
						<Heading>Details</Heading>
						{account && <AppSwitcher shop={account.shop} fromApp={app} />}
					</Stack>
				}
				sectioned>
				<div>
					<strong>Billing Status</strong>: {account.billingStatus}
				</div>
				<AlignCenter>
					<div>
						<strong>MyShopify Domain</strong>: {account.shop} <Badge tone="info">{account.platformPlan}</Badge>
					</div>
					<ClipboardButton icon={ClipboardIcon} content={account.shop} onCopy={() => showToast('Shop copied!')} variant="plain" />
				</AlignCenter>
				<Stack alignment="center">
					<span>
						<strong>Links</strong>:{' '}
					</span>
					<Link url={partnerLink} target="_blank">
						Shopify partner portal
					</Link>
					<Link url={helpScoutQueryUrl} target="_blank">
						Help Scout
					</Link>
					{intercomCompanyId && (
						<Link url={`https://app.intercom.com/a/apps/yewohyh0/companies/${intercomCompanyId}/users`} target="_blank">
							Intercom company
						</Link>
					)}
					{(account as MarketingPlatformAccount)?.hubspotId && (
						<Stack alignment="center" spacing="extraTight">
							<Link url={hubspotDealUrl} target="_blank">
								HubSpot deal
							</Link>
							<Tooltip content="Add note">
								<Button url={hubspotDealUrl + '?interaction=note'} icon={NoteAddIcon} size="large" target="_blank" variant="plain" />
							</Tooltip>
						</Stack>
					)}
				</Stack>
				{isLeadOrAdmin(user) && (
					<AlignCenter>
						<div>
							<strong>Shopify Access Token</strong>:{' '}
							{accessToken ? (
								accessToken
							) : (
								<Button onClick={requestDelegateAccessToken} loading={isLoading} variant="plain">
									Request token
								</Button>
							)}
						</div>
						{accessToken && (
							<ClipboardButton
								icon={ClipboardIcon}
								content={accessToken}
								onCopy={() => showToast('Access token copied!')}
								variant="plain"
							/>
						)}
					</AlignCenter>
				)}
				<AlignCenter>
					<div>
						<strong>Website</strong>:{' '}
						<Link url={domain} target="_blank">
							{domain}
						</Link>
					</div>
					<ClipboardButton
						icon={ClipboardIcon}
						content={domain.replace('https://', '')}
						onCopy={() => showToast('Website copied!')}
						variant="plain"
					/>
				</AlignCenter>
				<AlignCenter>
					<div>
						<strong>Owner</strong>: {account.ownerName} ({account.ownerEmail})
					</div>
					<ClipboardButton
						icon={ClipboardIcon}
						content={account.ownerEmail}
						onCopy={() => showToast('Owner email copied!')}
						variant="plain"
					/>
				</AlignCenter>
				{account.contactEmail && account.contactEmail !== account.ownerEmail && (
					<div>
						<strong>Contact</strong>: {account.contactName} ({account.contactEmail})
					</div>
				)}
				<Stack spacing="extraTight">
					<div>
						<strong>Address</strong>:
					</div>
					<Link
						url={getGoogleMapsUrl(
							`${account.address1}${account.address2 ? `,${account.address2}` : ''},${account.city},${account.provinceCode},${
								account.zip
							},${account.countryCode}`
						)}
						external>
						<div>{account.address1}</div>
						{account.address2 && <div>{account.address2}</div>}
						<div>
							{account.city}, {account.provinceCode} {account.zip} {account.countryCode}
						</div>
					</Link>
				</Stack>
				<div>
					<strong>Phone</strong>:{' '}
					<Link url={`tel:${account.phone}`} target="_blank">
						{account.phone}
					</Link>
				</div>
				{renderTimeZone()}
				<InlineStack wrap={false} blockAlign="center" gap="200">
					<div>
						<strong>Shop created</strong>: {renderDate(account.shopCreatedAt)}
					</div>
					<div>{marketingPlatformAccount.newShop && <Badge tone="info">new shop</Badge>}</div>
				</InlineStack>
				<InlineStack wrap={false} blockAlign="center" gap="200">
					<div>
						<strong>Installation date</strong>: {renderDate(account.createdAt)}
					</div>
					<div>{marketingPlatformAccount.fastInstall && <Badge tone="warning">fast install</Badge>}</div>
				</InlineStack>
				{!usesReviewRating && account.reviewSentiment && (
					<div>
						<strong>Sentiment</strong>: {account.reviewSentiment} {account.reviewSentiment === 'happy' ? ':)' : ':('}
					</div>
				)}
				{usesReviewRating && (
					<Stack alignment="center" spacing="extraTight">
						<strong>In-app rating:</strong> <ReviewRating rating={account.reviewRating} />
					</Stack>
				)}
			</Card>
			{accountSurvey && (
				<Card title="Account survey" sectioned key={account.id + '-survey'}>
					<div>
						<div>
							<strong>Contact name</strong>: {accountSurvey.contactName}
						</div>
						<div>
							<strong>Contact email</strong>: {accountSurvey.contactEmail}
						</div>
						<div>
							<strong>Industry</strong>: {accountSurvey.industry}
						</div>
						<div>
							<strong>Company size</strong>: {accountSurvey.companySize}
						</div>
					</div>
				</Card>
			)}
			{account.shopifyReview && (
				<Card title="Shopify review" sectioned key={account.shopifyReview.id}>
					<div>
						<div>
							<strong>Shop</strong>: {account.shopifyReview.company}
						</div>
						<Stack alignment="center" spacing="extraTight">
							<strong>Rating:</strong> <ReviewRating rating={account.shopifyReview.rating} />
						</Stack>
						<div>
							<strong>Date</strong>: {renderDate(account.shopifyReview.date)}
						</div>
						<div>
							<strong>Comments</strong>: {account.shopifyReview.comments}
						</div>
					</div>
				</Card>
			)}
		</>
	);
}

function ReviewRating({ rating, stars = 5 }: { rating: number; stars?: number }) {
	const starComponents = [];
	for (let i = 0; i < stars; i++) {
		starComponents.push(<Icon key={i} source={i < rating ? StarFilledIcon : StarIcon} tone="base" />);
	}
	return (
		<Stack alignment="center" spacing="extraTight" wrap={false}>
			{starComponents}
		</Stack>
	);
}

export const AlignCenter = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;
