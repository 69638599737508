import { queryKey } from '@segunosoftware/equinox';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BULK_ACCOUNTS } from '../query-keys';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export function useBulkAccountActions(id) {
	const { post, put } = useAuthenticatedFetch();
	const queryClient = useQueryClient();
	const mutationOptions = { onSuccess: data => queryClient.setQueryData(queryKey(BULK_ACCOUNTS, id), data) };

	function getActionPath(path) {
		return `/bulk-accounts/${id}${path}`;
	}

	const { mutate: setDiscountSetsAllowed, isLoading: isSettingDiscountSetsAllowed } = useMutation(
		discountSetsAllowed =>
			put(getActionPath('/set-discount-sets-allowed'), {
				discountSetsAllowed
			}),
		mutationOptions
	);

	const { mutate: setBasePrice, isLoading: isSettingBasePrice } = useMutation(
		basePrice =>
			put(getActionPath('/set-base-price'), {
				basePrice
			}),
		mutationOptions
	);

	const { mutate: cancelCharge, isLoading: isCancellingCharge } = useMutation(() => post(getActionPath('/cancel-charge')), mutationOptions);

	return {
		setDiscountSetsAllowed,
		isSettingDiscountSetsAllowed,
		setBasePrice,
		isSettingBasePrice,
		cancelCharge,
		isCancellingCharge
	};
}
