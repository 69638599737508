import { Card, FormatMoney, Stack, getNumber } from '@segunosoftware/equinox';
import { Badge, InlineStack, Labelled, Layout, ProgressBar, SkeletonBodyText } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMailchimpConnectorAccount } from '../../hooks/mailchimpconnector/useMailchimpConnectorAccount';
import { useUser } from '../../hooks/useUser';
import AccountView, { isLeadOrAdmin } from '../AccountView';
import AdminPage from '../AdminPage';
import AppCreditModal from '../AppCreditModal';
import JobsView from '../JobsView';
import MailchimpDetails from './MailchimpDetails';

export default function MarketingPlatformAccountView() {
	const { accountId } = useParams();
	const user = useUser();
	const { account, jobs, onLoadJobs, isJobsLoading, mailchimpDetails, onLoadMailchimpDetails, isMailchimpDetailsLoading } =
		useMailchimpConnectorAccount(accountId);
	const {
		initialSyncStarted,
		initialSyncComplete,
		shopifyTotalCustomers,
		shopifyTotalOrders,
		shopifyTotalProducts,
		shopifyTotalAbandonedCheckouts,
		mailchimpTotalMembers,
		mailchimpTotalMembersSynced,
		shopifyTotalCustomersSynced,
		shopifyTotalOrdersSynced,
		shopifyTotalProductsSynced,
		shopifyTotalAbandonedCheckoutsSynced
	} = account?.syncStatus ?? {};

	const [showAppCreditModal, setShowAppCreditModal] = useState(false);
	const onCloseAppCreditModal = useCallback(() => setShowAppCreditModal(false), []);

	useEffect(() => {
		if (account?.mailChimpConnected && !mailchimpDetails) {
			onLoadMailchimpDetails();
		}
	}, [account?.mailChimpConnected, mailchimpDetails, onLoadMailchimpDetails]);

	function renderSyncStatus() {
		if (!account.mailChimpConnected || !initialSyncStarted) {
			return 'Not started';
		} else if (!initialSyncComplete) {
			return 'Running';
		} else {
			return 'Completed';
		}
	}

	function renderStatus(label, total, completed) {
		const absoluteTotal = Math.max(total, completed);
		const progress = initialSyncComplete ? 100 : total === 0 ? 0 : (completed / absoluteTotal) * 100.0;
		return (
			<Labelled label={`${label} - ${getNumber(completed)} of ${getNumber(absoluteTotal)}`}>
				<ProgressBar progress={progress} />
			</Labelled>
		);
	}

	const badges = [];
	if (account?.trialEndsAt) {
		badges.push(
			<Badge key="trial" tone="info">
				trial ends:{' '}
				{new Date(account.trialEndsAt).toLocaleDateString('en-US', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit'
				})}
			</Badge>
		);
	}

	const billingActions = [];

	function addBillingOptions(hasPermissions, options) {
		if (hasPermissions) {
			billingActions.push(...options);
		}
	}

	if (account) {
		addBillingOptions(isLeadOrAdmin(user), [
			{
				content: 'Issue app credit',
				onAction: () => setShowAppCreditModal(true)
			}
		]);
	}

	return (
		<AdminPage
			title={
				<InlineStack align="center">
					<div>{account?.name ?? 'Mail connect account'}</div>
					{badges}
				</InlineStack>
			}
			pageTitle={account?.name ?? 'Mail connect account'}
			backAction={{ content: 'Dashboard', url: '/' }}
			actionGroups={[{ title: 'More actions', actions: billingActions }]}>
			<Layout sectioned>
				<AccountView account={account} app="MAIL_CONNECT" />
				<Card title="Billing and usage" sectioned>
					{!account && <SkeletonBodyText />}
					{account && (
						<>
							<div>
								<strong>Monthly Price</strong>: <FormatMoney amount={account.monthlyPrice} />
							</div>
							<div>
								<strong>Mailchimp Connected</strong>: {account.mailChimpConnected ? 'Yes' : 'No'}
							</div>
							<div>
								<strong>Subscribe Existing Shopify Customers</strong>: {account.accountSettings.shopifySubscribeExisting ? 'Yes' : 'No'}
							</div>
							<div>
								<strong>Sync Status:</strong> {renderSyncStatus()}
							</div>
							{initialSyncStarted && (
								<Stack vertical>
									<br />
									{renderStatus('Mailchimp contacts', mailchimpTotalMembers, mailchimpTotalMembersSynced)}
									{renderStatus('Shopify customers', shopifyTotalCustomers, shopifyTotalCustomersSynced)}
									{renderStatus('Shopify products', shopifyTotalProducts, shopifyTotalProductsSynced)}
									{renderStatus('Shopify orders', shopifyTotalOrders, shopifyTotalOrdersSynced)}
									{renderStatus('Shopify abandoned checkouts', shopifyTotalAbandonedCheckouts, shopifyTotalAbandonedCheckoutsSynced)}
								</Stack>
							)}
						</>
					)}
				</Card>
				{account?.mailChimpConnected && <MailchimpDetails mailchimpDetails={mailchimpDetails} isLoading={isMailchimpDetailsLoading} />}
				<JobsView onLoad={onLoadJobs} jobs={jobs} isLoading={isJobsLoading} />
			</Layout>
			{account && <AppCreditModal account={account} app="MAIL_CONNECT" onClose={onCloseAppCreditModal} open={showAppCreditModal} />}
		</AdminPage>
	);
}
