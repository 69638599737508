import { Card, FormatMoney, FormatNumber, usePrevious } from '@segunosoftware/equinox';
import { Badge, Button, Form, FormLayout, Modal, TextField } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useBulkAccount } from '../../hooks/bulk/useBulkAccount';
import { useBulkAccountActions } from '../../hooks/bulk/useBulkAccountActions';
import { useBulkAccountDetails } from '../../hooks/bulk/useBulkAccountDetails';
import { useUser } from '../../hooks/useUser';
import { renderDate } from '../../utils/dateUtils';
import { isLeadOrAdmin } from '../AccountView';

const MAX_DISCOUNT_CODES = 20_000_000;
const DISCOUNT_CODES_WARN_LEVEL = MAX_DISCOUNT_CODES * 0.95;

BulkBillingAndUsageSummary.propTypes = {
	account: PropTypes.shape({
		id: PropTypes.number.isRequired,
		basePrice: PropTypes.number.isRequired,
		discountSetsAllowed: PropTypes.number.isRequired,
		discountSetsCreated: PropTypes.number.isRequired
	}).isRequired
};

export default function BulkBillingAndUsageSummary({ account }) {
	const user = useUser();
	const { details, onLoadDetails } = useBulkAccountDetails(account.id);
	const { setDiscountSetsAllowed, isSettingDiscountSetsAllowed, setBasePrice, isSettingBasePrice, cancelCharge, isCancellingCharge } =
		useBulkAccountActions(account.id);
	const { billingDate } = useBulkAccount(account.id);

	const [showSetDiscountSetsAllowedModal, setShowSetDiscountSetsAllowedModal] = useState(false);
	const [discountSetsAllowedValue, setDiscountSetsAllowedValue] = useState(account.discountSetsAllowed);

	const [showSetBasePriceModal, setShowSetBasePriceModal] = useState(false);
	const [basePriceValue, setBasePriceValue] = useState(account.basePrice);

	const [showCancelChargeModal, setShowCancelChargeModal] = useState(false);

	const previousIsCancellingCharge = usePrevious(isCancellingCharge);
	const previousIsSettingDiscountSetsAllowed = usePrevious(isSettingDiscountSetsAllowed);
	const previousIsSettingBasePrice = usePrevious(isSettingBasePrice);

	const showDiscountCodesBadge = details?.shopifyDiscountCodeCount > DISCOUNT_CODES_WARN_LEVEL;
	const isAtMaximumDiscountCodes = details?.shopifyDiscountCodeCount >= MAX_DISCOUNT_CODES;

	useEffect(() => {
		if (!isCancellingCharge && previousIsCancellingCharge) {
			setShowCancelChargeModal(false);
			onLoadDetails();
		}
		if (!isSettingDiscountSetsAllowed && previousIsSettingDiscountSetsAllowed) {
			setShowSetDiscountSetsAllowedModal(false);
			onLoadDetails();
		}
		if (!isSettingBasePrice && previousIsSettingBasePrice) {
			setShowSetBasePriceModal(false);
			onLoadDetails();
		}
	}, [
		onLoadDetails,
		isCancellingCharge,
		previousIsCancellingCharge,
		isSettingDiscountSetsAllowed,
		previousIsSettingDiscountSetsAllowed,
		isSettingBasePrice,
		previousIsSettingBasePrice
	]);

	useEffect(() => {
		if (showSetDiscountSetsAllowedModal) {
			setDiscountSetsAllowedValue(account.discountSetsAllowed);
		}
	}, [account.discountSetsAllowed, showSetDiscountSetsAllowedModal]);

	const isSetDiscountSetsFormValid = Number(discountSetsAllowedValue) > 0;

	function onSetDiscountSetsAllowed() {
		if (isSettingDiscountSetsAllowed || !isSetDiscountSetsFormValid) {
			return false;
		}
		setDiscountSetsAllowed(discountSetsAllowedValue);
	}

	const isSetBasePriceFormValid = Number(basePriceValue) >= 0;

	function onSetBasePrice() {
		if (isSettingBasePrice || !isSetBasePriceFormValid) {
			return false;
		}
		// Convert to cents first
		setBasePrice(basePriceValue * 100);
	}

	const leadOrAdmin = isLeadOrAdmin(user);

	return (
		<Card title="Billing and usage" sectioned>
			<div>
				<strong>Base price</strong>: <FormatMoney amount={account.basePrice} decimals={0} />
				{leadOrAdmin && (
					<span>
						{' '}
						(
						<Button onClick={() => setShowSetBasePriceModal(true)} disabled={details?.activeChargeAmount > 0} variant="plain">
							change
						</Button>
						)
					</span>
				)}
			</div>
			<div>
				<strong>Active monthly charge</strong>: <FormatMoney amount={details?.activeChargeAmount} />{' '}
				{details?.activeChargeAmount > 0 && (
					<Button onClick={() => setShowCancelChargeModal(true)} variant="plain" tone="critical">
						(cancel charge)
					</Button>
				)}
			</div>
			{billingDate && (
				<div>
					<strong>Next billing date</strong>: {renderDate(billingDate)}
				</div>
			)}
			<div>
				<strong>Free discount sets allowed</strong>: <FormatNumber value={account.discountSetsAllowed} /> (
				<Button onClick={() => setShowSetDiscountSetsAllowedModal(true)} variant="plain">
					change
				</Button>
				)
			</div>
			<div>
				<strong>Discount sets used</strong>: <FormatNumber value={account.discountSetsCreated} />
			</div>
			<div>
				<strong>Shopify discount codes</strong>: <FormatNumber value={details?.shopifyDiscountCodeCount} />{' '}
				{showDiscountCodesBadge && (
					<Badge tone={isAtMaximumDiscountCodes ? 'critical' : 'warning'}>{isAtMaximumDiscountCodes ? 'Full' : 'Running low'}</Badge>
				)}
			</div>
			<Modal
				title="Confirm charge cancellation"
				primaryAction={{
					content: 'Confirm',
					onAction: cancelCharge,
					destructive: true,
					loading: isCancellingCharge
				}}
				secondaryActions={[{ content: 'Cancel', onAction: () => setShowCancelChargeModal(false) }]}
				onClose={() => setShowCancelChargeModal(false)}
				open={showCancelChargeModal}>
				<Modal.Section>
					<p>
						This will cancel the charge with Shopify. If the merchant is in the free trial, the charge will be cancelled. If not in the free
						trial, the charge will be cancelled and the account may become frozen if they are over their impression limit.
					</p>
				</Modal.Section>
			</Modal>
			<Modal
				title="Set discount sets allowed"
				primaryAction={{
					content: 'Set value',
					onAction: onSetDiscountSetsAllowed,
					loading: isSettingDiscountSetsAllowed,
					disabled: !isSetDiscountSetsFormValid
				}}
				secondaryActions={[{ content: 'Cancel', onAction: () => setShowSetDiscountSetsAllowedModal(false) }]}
				onClose={() => setShowSetDiscountSetsAllowedModal(false)}
				open={showSetDiscountSetsAllowedModal}>
				<Modal.Section>
					<Form onSubmit={onSetDiscountSetsAllowed}>
						<FormLayout>
							<TextField
								label="Discount sets allowed"
								type="number"
								min={0}
								value={discountSetsAllowedValue}
								onChange={discountSetsAllowedValue => setDiscountSetsAllowedValue(Number(discountSetsAllowedValue))}
								autoFocus
							/>
						</FormLayout>
					</Form>
				</Modal.Section>
			</Modal>
			<Modal
				title="Set base price"
				primaryAction={{
					content: 'Set price',
					onAction: onSetBasePrice,
					loading: isSettingBasePrice,
					disabled: !isSetBasePriceFormValid
				}}
				secondaryActions={[{ content: 'Cancel', onAction: () => setShowSetBasePriceModal(false) }]}
				onClose={() => setShowSetBasePriceModal(false)}
				open={showSetBasePriceModal}>
				<Modal.Section>
					<Form onSubmit={onSetDiscountSetsAllowed}>
						<FormLayout>
							<TextField
								label="Base price"
								type="number"
								prefix="$"
								suffix="USD"
								min={1}
								value={basePriceValue}
								onChange={basePrice => setBasePriceValue(Number(basePrice))}
								autoFocus
							/>
						</FormLayout>
					</Form>
				</Modal.Section>
			</Modal>
		</Card>
	);
}
